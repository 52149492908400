import React from 'react';
import { Route, Switch } from 'react-router';
import routes from '../../constants/routes';
import FindAMosqueView from './FindAMosqueView';
import MosqueBookingView from './mosquebooking/MosqueBookingView';

const MosqueRoutes = () => (
  <Switch>
    <Route exact path={routes.MOSQUE_FIND_MOSQUE}>
      <FindAMosqueView />
    </Route>
    <Route exact path={`${routes.MOSQUE_BOOK}/:slug`}>
      <MosqueBookingView />
    </Route>
    <Route>
      <FindAMosqueView />
    </Route>
  </Switch>
);

export default MosqueRoutes;
