import React, { useContext, useState } from 'react';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../../../providers/AuthProvider';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import SuccessAlert from '../../../components/alerts/SuccessAlert';
import BottomCardView from '../../../components/BottomCardView';
import styled from 'styled-components';
import newMessageImage from '../../../assets/undraw_new_message.svg';
import { Link } from 'react-router-dom';

type Props = {
  onClose: (type?: string) => void,
};

const MagicLinkSignInView = (props: Props) => {
  const [email, setEmail] = useState('');
  const [error: string, setError] = useState(null);
  const [hasSent, setHasSent] = useState(false);

  const authContext = useContext(AuthContext);

  function doMagicLinkSignIn() {
    setError(null);
    authContext
      .doSendMagicEmailLoginLink(email)
      .then(() => setHasSent(true))
      .catch((e) => setError(e.message));
  }

  return (
    <BottomCardView style={{ alignItems: 'center', flex: 1 }}>
      <View>
        <img
          src={newMessageImage}
          height={'auto'}
          width={'90%'}
          style={{ maxWidth: '500px' }}
          alt=''
        />
        {error && <ErrorAlert>{error}</ErrorAlert>}
        {hasSent && !error && <SuccessAlert>Check your inbox!</SuccessAlert>}
        <FormView>
          <FormInput>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              size={'lg'}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormInput>
        </FormView>

        <PrimaryButton onClick={doMagicLinkSignIn}>
          {hasSent ? 'Resend Email Link!' : 'Send Email Link!'}
        </PrimaryButton>

        <FormInput
          style={{
            width: '100%',
            paddingTop: '10px',
            borderTop: 'solid #e1e1e1 1px',
            marginTop: '10px',
          }}>
          <Link
            to={'#'}
            style={{ fontStyle: 'underlined' }}
            onClick={() => props.onClose()}>
            Go back
          </Link>
        </FormInput>
      </View>
    </BottomCardView>
  );
};

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
`;

const FormView = styled(ViewWrapper)`
  width: 100%;
  padding: 10px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const FormInput = styled(ViewWrapper)`
  width: 100%;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

export default MagicLinkSignInView;
