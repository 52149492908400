import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureSentry } from './config/sentryConfig';
import { BrowserRouter } from 'react-router-dom';
import { COLOR_PRIMARY } from './constants/styles';
import { AuthProvider } from './providers/AuthProvider';
import {initialiseFirebaseApp} from './config/firebaseConfig';
import {FirebaseRemoteConfigProvider} from './providers/FirebaseRemoteConfigProvider';

configureSentry();
initialiseFirebaseApp();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter style={{ backgroundColor: COLOR_PRIMARY }}>
      <AuthProvider>
        <FirebaseRemoteConfigProvider>
          <App />
        </FirebaseRemoteConfigProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
