import React from 'react';
import { StaticMap } from 'react-map-gl';

import MosqueWithSlots from '../models/MosqueWithSlots';

const MosqueMap = (props: { mosqueWithSlots: MosqueWithSlots }) => {
  const location = props.mosqueWithSlots.location;

  return (
    <StaticMap
      width={'100%'}
      height={150}
      zoom={13}
      latitude={location ? location.latitude : 52.1}
      longitude={location ? location.longitude : 10.1}
      {...props}
      mapStyle={'mapbox://styles/salahspace/ckc7nndp82ff41iqhp0269ly5'}
      style={{
        borderRadius: '10px',
        overflow: 'hidden',
        minHeight: 'max-content',
        display: 'block',
      }}
    />
  );
};

export default MosqueMap;
