import ViewWrapper from '../../components/theme/ViewWrapper';
import ViewWithHeading from '../../components/ViewWithHeading';
import React from 'react';
import image from '../../assets/undraw_a_moment_to_relax_bbpa.svg';
import SubheadingText from '../../components/text/SubheadingText';
import { COLOR_PRIMARY } from '../../constants/styles';
import { APP_COMING_SOON_MESSAGE } from '../../config/appConfig';

const message =
  APP_COMING_SOON_MESSAGE ||
  'You will be able to book for jumuah from Wednesday morning.';

const ComingSoon = () => (
  <ViewWrapper
    style={{
      display: 'flex',
      flex: 1,
      height: '100vh',
      backgroundColor: COLOR_PRIMARY,
      overflowY: 'scroll',
      overflowX: 'hidden',
    }}>
    <ViewWithHeading title={'Salah Space'}>
      <ViewWrapper
        style={{
          padding: '20px 10px',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
        <img
          src={image}
          height={'100%'}
          width={'auto'}
          style={{ maxHeight: '250px', marginBottom: '30px' }}
          alt=''
        />
        <SubheadingText>{message}</SubheadingText>
      </ViewWrapper>
    </ViewWithHeading>
  </ViewWrapper>
);

export default ComingSoon;
