class User {
  name: string;
  email: string;
  phone: string;
  gender: string;

  constructor(
      name: string, email: string, phone: string, gender: string) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.gender = gender;
  }
}

export default User;