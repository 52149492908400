class Mosque {
  masjid_id: string;
  name: string;
  address: string;
  distance: number;
  availability_status: string;
  slug: string;

  constructor(
      masjid_id: string, name: string, address: string, distance: number,
      availability_status: string, slug: string) {
    this.masjid_id = masjid_id;
    this.name = name;
    this.address = address;
    this.distance = distance;
    this.availability_status = availability_status;
    this.slug = slug;
  }
}

export default Mosque;