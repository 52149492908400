import axios from 'axios';
import {API_URL} from '../config/apiConfig';
import MosqueBooking from '../models/MosqueBooking';
import MosqueBookingRequest from '../models/MosqueBookingRequest';
import MosqueBookingResponse from '../models/MosqueBookingResponse';
import {
  getDataFromResponse,
  responseErrorHandler,
} from '../config/axiosConfig';
import Logger from '../Logger';

const ENDPOINT_PATH = '/booking';

class BookingService {
  constructor() {
    this.logger = new Logger(BookingService.name);
  }

  bookMosque = async (bookings: Array<MosqueBookingRequest>): MosqueBooking => {
    const response = await axios
      .post(API_URL + ENDPOINT_PATH, {
        bookings: bookings,
      })
      .catch((err) =>
        responseErrorHandler(
          err,
          'Sorry but we failed to make your booking, please check your details are correct. Otherwise please get in touch with us.'
        )
      );

    const data = getDataFromResponse(response);
    return Object.assign(new MosqueBookingResponse(), data);
  };

  deleteBooking = async (bookingId: string): string => {
    const response = await axios
      .delete(API_URL + ENDPOINT_PATH + '/' + encodeURIComponent(bookingId))
      .catch((err) =>
        responseErrorHandler(
          err,
          'Sorry we were unable to delete your booking, please try again later.'
        )
      );

    return getDataFromResponse(response);
  };

  getCurrentBooking = async (): Array<MosqueBooking> => {
    const response = await axios
      .get(API_URL + ENDPOINT_PATH)
      .catch((err) =>
        responseErrorHandler(
          err,
          'Sorry we were unable to fetch your booking. Please try again or get in touch with us.'
        )
      );

    const data = getDataFromResponse(response);
    this.logger.info('data', data);
    return data.map((obj) => Object.assign(new MosqueBooking(), obj));
  };
}

export default new BookingService();
