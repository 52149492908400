import Form from 'react-bootstrap/Form';
import React from 'react';
import styled from 'styled-components';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import TextWrapper from '../../../components/theme/TextWrapper';
import BookingPanelDetail, {
  BookingPanel,
  BookingPanelDetailTitle,
} from './BookingPanelDetail';
import {faUserSecret} from '@fortawesome/free-solid-svg-icons/faUserSecret';

function PersonalDetailsForm(props: {setName: (name: string) => void, setContact:  (contact: string) => void}) {

  const {setName, setContact} = {...props};
  return (
        <BookingPanel>
          <BookingPanelDetail icon={faUserSecret} isDark={true}>
            <BookingPanelDetailTitle>
              Your Personal Details
            </BookingPanelDetailTitle>
          </BookingPanelDetail>
          <TextWrapper>
            The following fields are <b>optional</b>. The data below may be
            shared with the Mosque or with the NHS for test and trace.
          </TextWrapper>
          <FormInput>
            <Form.Label>Name</Form.Label>
            <Form.Control
                type='text'
                placeholder='Enter your name (optional)'
                onChange={(event) => setName(event.target.value)}
            />
          </FormInput>
          <FormInput>
            <Form.Label>Contact</Form.Label>
            <Form.Control
                type='text'
                placeholder='Enter your phone number (optional)'
                onChange={(event) => setContact(event.target.value)}
            />
          </FormInput>
        </BookingPanel>
  )
}

const FormInput = styled(ViewWrapper)`
  width: 100%;
  margin: 10px;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

export default PersonalDetailsForm;