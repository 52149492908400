import React from 'react';
import { Route, Switch } from 'react-router';
import routes from '../../constants/routes';
import MyCurrentBookingsView from './MyCurrentBookingsView';
import MyPreviousBookingsView from './MyPreviousBookingsView';
import MyBookingDetailsView from './MyBookingDetailsView';

const BookingRoutes = () => (
  <Switch>
    <Route exact path={routes.BOOKINGS_CURRENT}>
      <MyCurrentBookingsView />
    </Route>
    <Route exact path={routes.BOOKINGS_DETAIL + '/:id'}>
      <MyBookingDetailsView/>
    </Route>
    <Route exact path={routes.BOOKINGS_HISTORY}>
      <MyPreviousBookingsView />
    </Route>
    <Route>
      <MyCurrentBookingsView />
    </Route>
  </Switch>
);

export default BookingRoutes;
