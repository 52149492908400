import React, {Fragment, useContext, useEffect} from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import routes from './constants/routes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewWrapper from './components/theme/ViewWrapper';
import { APP_COMING_SOON } from './config/appConfig';
import { AuthContext } from './providers/AuthProvider';
import LoginRouter from './views/login/LoginRouter';
import LoadingCircles from './components/loading/LoadingCircles';
import MosqueRoutes from './views/mosques/MosqueRoutes';
import AccountRoutes from './views/account/AccountRoutes';
import BookingRoutes from './views/bookings/BookingRoutes';
import ComingSoon from './views/maintenance/ComingSoon';
import Menu from './components/Menu';
import Dock, {DockItem} from './components/Dock';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {faCalendar} from '@fortawesome/free-solid-svg-icons/faCalendar';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faMosque} from '@fortawesome/free-solid-svg-icons/faMosque';
import {FirebaseRemoteConfigContext} from './providers/FirebaseRemoteConfigProvider';

function App() {
  const authContext = useContext(AuthContext);
  const firebaseRemoteConfigContext = useContext(FirebaseRemoteConfigContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0,0); // scroll to top when path changes
  }, [location.pathname]);
  //
  // if (
  //   !authContext.loading &&
  //   authContext.isLoggedIn() &&
  //   authContext.redirectPath
  // ) {
  //   const redirectPath = authContext.redirectPath;
  //   authContext.setRedirectPath(null);
  //   return <Redirect to={redirectPath} />;
  // }

  function renderView() {
    if (authContext.loading || firebaseRemoteConfigContext.loading) {
      return <LoadingCircles>Loading app...</LoadingCircles>;
    } else if (firebaseRemoteConfigContext.isMaintenanceMode()) {
      return <ComingSoon/>;
    } else if (
        !authContext.loading &&
        authContext.isLoggedIn() &&
        authContext.redirectPath
    ) {
      const redirectPath = authContext.redirectPath;
      authContext.setRedirectPath(null);
      return <Redirect to={redirectPath}/>;
    } else {
      return (
          <ViewWrapper style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'scroll',
            marginBottom: '55px'
          }}>
            <Switch>
              <Route path={routes.MOSQUE_BASE}>
                <MosqueRoutes/>
              </Route>
              <Route path={routes.ACCOUNT_BASE}>
                <AccountRoutes/>
              </Route>
              <Route path={routes.BOOKINGS_BASE}>
                <BookingRoutes/>
              </Route>
              <Route path={routes.LOGIN_BASE}>
                <LoginRouter/>
              </Route>
              <Redirect to={routes.MOSQUE_BASE}/>
            </Switch>
          </ViewWrapper>
      );
    }
  }

  return (
    <Fragment>
      <ViewWrapper
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: '100%',
          minHeight: window.innerHeight,
          backgroundColor: 'white',
        }}>
        {renderView()}
        <Dock>
          <DockItem icon={faMosque} label={"Mosques"} selected={location.pathname.startsWith(routes.MOSQUE_BASE)} onClick={() => history.push(routes.MOSQUE_BASE)}/>
          <DockItem icon={faCalendar} label={"Bookings"} selected={location.pathname.startsWith(routes.BOOKINGS_BASE)} onClick={() => history.push(routes.BOOKINGS_BASE)}/>
          <DockItem icon={faUser} label={"Account"} selected={location.pathname.startsWith(routes.ACCOUNT_BASE)} onClick={() => history.push(routes.ACCOUNT_BASE)}/>
        </Dock>
      </ViewWrapper>
    </Fragment>
  );
}

export default App;
