import axios from 'axios';
import { API_URL } from '../config/apiConfig';
import User from '../models/User';
import {
  getDataFromResponse,
  responseErrorHandler,
} from '../config/axiosConfig';
import Logger from '../Logger';

const ENDPOINT_PATH = '/user';

class UserService {
  constructor() {
    this.logger = new Logger(UserService.name);
  }

  signInToServer = async (user: User): User => {
    this.logger.info('Signing up user', user);
    const response = await axios
      .post(API_URL + ENDPOINT_PATH, user)
      .catch((err) =>
        responseErrorHandler(
          err,
          'Sorry we were unable to sign you in, please try again or get in touch with us if this occurs again.'
        )
      );

    const data = getDataFromResponse(response);
    return Object.assign(new User(), data);
  };

  getUserByFirebaseId = async (firebase_uid: string): User => {
    this.logger.info('Fetching user by uid', firebase_uid);
    const response = await axios
      .get(API_URL + ENDPOINT_PATH + '/' + firebase_uid, firebase_uid)
      .catch((err) =>
        responseErrorHandler(
          err,
          'Sorry we were unable to fetch your user details, please try again or get in touch with us if this occurs again.'
        )
      );

    const data = getDataFromResponse(response);
    return Object.assign(new User(), data);
  };

  updateUserDetails = async (user: User): string => {
    const response = await axios
      .post(API_URL + '/mosque-prayers', user)
      .catch((err) =>
        responseErrorHandler(
          err,
          'Sorry we were unable to updated your user details, please try again later.'
        )
      );

    return getDataFromResponse(response);
  };
}

export default new UserService();
