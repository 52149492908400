import axios from 'axios';
import Mosque from '../models/Mosque';
import { API_URL } from '../config/apiConfig';
import MosqueWithSlots from '../models/MosqueWithSlots';
import {
  getDataFromResponse,
  responseErrorHandler,
} from '../config/axiosConfig';
import Logger from '../Logger';

const PUBLIC_MOSQUES_ENDPOINT_PATH = '/public/masjid';

class MosquesService {
  constructor() {
    this.logger = new Logger(MosquesService.name);
  }

  getMosquesByLocation = async (lat: number, lng: number): Array<Mosque> => {
    this.logger.info('Fetching mosques by location', lat, lng);
    const response = await axios
      .get(API_URL + PUBLIC_MOSQUES_ENDPOINT_PATH + '/search', {
        params: {
          latitude: lat,
          longitude: lng,
        },
      })
      .catch((err) =>
        responseErrorHandler(err, 'We could not find any mosques near you.')
      );

    const data = getDataFromResponse(response);
    return data.map((obj) => Object.assign(new Mosque(), obj));
  };

  getMosquesByPostcode = async (postcode: string): Array<Mosque> => {
    this.logger.info('Fetching mosques by postcode', postcode);
    const response = await axios
      .get(API_URL + PUBLIC_MOSQUES_ENDPOINT_PATH + '/search', {
        params: {
          postcode: postcode,
        },
      })
      .catch((err) =>
        responseErrorHandler(
          err,
          'We culd not find any mosques near your postcode or maybe your postcode was invalid.'
        )
      );

    const data = getDataFromResponse(response);
    return data.map((obj) => Object.assign(new Mosque(), obj));
  };

  getMosquesById = async (mosqueId: string): MosqueWithSlots => {
    this.logger.info('Fetching mosque by id', mosqueId);
    const response = await axios
      .get(API_URL + PUBLIC_MOSQUES_ENDPOINT_PATH + '/' + mosqueId)
      .catch((err) =>
        responseErrorHandler(err, 'We could not find any mosques.')
      );

    const data = getDataFromResponse(response);
    return Object.assign(new MosqueWithSlots(), data);
  };

  getMosquesBySlug = async (slug: string): MosqueWithSlots => {
    this.logger.info('Fetching mosque by slug', slug);
    const response = await axios
      .get(API_URL + PUBLIC_MOSQUES_ENDPOINT_PATH + '/slug/' + slug)
      .catch((err) =>
        responseErrorHandler(err, 'We could not find any mosques.')
      );

    const data = getDataFromResponse(response);
    return Object.assign(new MosqueWithSlots(), data);
  };
}

export default new MosquesService();
