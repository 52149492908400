// @flow
import React, { useContext, useEffect, useState } from 'react';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { AuthContext } from '../../../providers/AuthProvider';
import { useHistory } from 'react-router';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import LoadingCircles from '../../../components/loading/LoadingCircles';
import routes from '../../../constants/routes';

function MagicLinkVerifyView() {
  const [error: string, setError] = useState(null);
  const [isLoading: boolean, setIsLoading] = useState(true);

  const authContext = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      authContext
        .doMagicEmailSignIn(window.location.href)
        .catch((e) => setError(e.message))
        .finally(() => {
          setIsLoading(false);
        });
    }, 2000);
  }, [null]);

  return (
    <ViewWrapper
      style={{
        flex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <ViewWrapper
        style={{
          padding: '20px 10px',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}>
        <ViewWrapper
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}>
          {error && <ErrorAlert>{error}</ErrorAlert>}
          {isLoading && (
            <LoadingCircles size={120}>Signing you in</LoadingCircles>
          )}
        </ViewWrapper>

        <PrimaryButton onClick={() => history.push(routes.HOME)}>
          {isLoading ? 'Cancel' : 'Go back'}
        </PrimaryButton>
      </ViewWrapper>
    </ViewWrapper>
  );
}

export default MagicLinkVerifyView;
