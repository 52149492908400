import ViewWrapper from './theme/ViewWrapper';
import React from 'react';
import styled from 'styled-components';

const BottomCardView = (props) => <Card {...props} />;

const Card = styled(ViewWrapper)`
  background-color: white;
  border-color: white;
  border-style: solid;
  border-radius: 20px 20px 0 0;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  flex: 1;
  min-height: max-content;
`;

export default BottomCardView;
