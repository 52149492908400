import React, { Fragment, useContext, useEffect, useState } from 'react';
import ViewWrapper from '../../components/theme/ViewWrapper';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import MosqueBooking from '../../models/MosqueBooking';
import { useHistory } from 'react-router';
import {
  AdditionalHeadingView,
} from '../../components/ViewWithHeading';
import BottomCardView from '../../components/BottomCardView';
import TextWrapper from '../../components/theme/TextWrapper';
import { COLOR_PRIMARY, COLOR_TEXT_DARK } from '../../constants/styles';
import moment from 'moment';
import BookingQrCode from './BookingQrCode';
import ListCard from '../../components/ListCard';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import styled from 'styled-components';
import MosqueMap from '../../components/MosqueMap';
import MosqueNotes from '../../components/mosque/MosqueNotes';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import HeadingText from '../../components/text/HeadingText';
import { AuthContext } from '../../providers/AuthProvider';
import routes from '../../constants/routes';
import { motion } from "framer-motion"
import {useWindowSize} from 'react-use';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import AccentButton from '../../components/buttons/AccentButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import DangerButton from '../../components/buttons/DangerButton';
import bookingService from '../../services/bookingService';
import LoadingCircles from '../../components/loading/LoadingCircles';

function MyBookingDetailsView(props :  {mosqueBooking: MosqueBooking, onDelete: (MosqueBooking) => void, onClose: () => void}) {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { height, width } = useWindowSize();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [error: string, setError] = useState(null);
  const [loading: string, setLoading] = useState(null);

  const mosqueBooking = props.mosqueBooking;
  const mosque = mosqueBooking.masjid;


  function onDelete() {
    setLoading("Deleting booking...");
    bookingService.deleteBooking(mosqueBooking.booking_id)
        .then(() => props.onDelete(mosqueBooking))
        .catch((e) => {
          setError(e.message);
          setLoading(null);
        })
        .finally(() => {
          window.scrollTo(0,0);
        });
  }

  function renderActionButtons() {
      if(confirmDelete) {
        return (
            <Fragment>
              <TextWrapper><b>Are you sure you want to delete this booking?</b></TextWrapper>
              <ViewWrapper style={{display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <PrimaryButton onClick={() => setConfirmDelete(false)}>Cancel</PrimaryButton>
                <DangerButton onClick={onDelete}>Delete!</DangerButton>
              </ViewWrapper>
            </Fragment>
        );
      } else {
        return <DangerButton onClick={() => setConfirmDelete(true)}>Delete Booking</DangerButton>
      }
  }

  function renderTags() {
    return (
        <ViewWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '100%',
              marginTop: '10px',
            }}>
          <ViewWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                paddingBottom: '15px',
              }}>
            <Tag>{mosqueBooking.slot.name}</Tag>
            {mosqueBooking.num_people_male > 1 ? (
                <Tag>{mosqueBooking.num_people_male} male people</Tag>
            ) : mosqueBooking.num_people_male > 0 ? (
                <Tag>{mosqueBooking.num_people_male} male person</Tag>
            ) : (
                false
            )}
            {mosqueBooking.num_people_female > 1 ? (
                <Tag>{mosqueBooking.num_people_female} female people</Tag>
            ) : mosqueBooking.num_people_female > 0 ? (
                <Tag>{mosqueBooking.num_people_female} female person</Tag>
            ) : (
                false
            )}
          </ViewWrapper>
        </ViewWrapper>
    )
  }

  function renderSpaceReserved() {
    if (mosqueBooking != null) {
      const dateStr = moment(mosqueBooking.date).format('dddd Do MMMM YYYY');
      const eidConfirm = `Please confirm date with Masjid`;
      return (
          <Fragment>
            <WhiteBoxRounded>
              <TextWrapper
                  style={{
                    color: COLOR_PRIMARY,
                    fontWeight: 800,
                    textAlign: 'center',
                  }}>
                {mosqueBooking &&
                mosqueBooking.slot.salah == 'eid' && dateStr == 'Invalid date' ? eidConfirm : dateStr}
                <br />
                Your space has been confirmed
              </TextWrapper>
            </WhiteBoxRounded>
            {mosqueBooking && mosqueBooking.slot && (
                <Slots>
                  <ListCard.Detail icon={faDoorOpen} isDark={true}>
                    Doors Open: {mosqueBooking.slot.timing.open}
                  </ListCard.Detail>
                  <ListCard.Detail icon={faClock} isDark={true}>
                    <TextWrapper style={{textTransform: 'capitalize'}}>{mosqueBooking.slot.name} Starts: {mosqueBooking.slot.timing.start}</TextWrapper>
                  </ListCard.Detail>
                </Slots>
            )}
            <TextWrapper
                style={{
                  fontWeight: '700',
                  fontSize: '1.1em',
                  textAlign: 'center',
                  color: 'white',
                  marginBottom: '15px',
                }}>
              Present QR code when entering the Mosque
            </TextWrapper>
          </Fragment>
      );
    }
  }


  function renderComponents() {
    if (!authContext.isLoggedIn()) {
      return (

          <ViewWrapper
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}>
            <ErrorAlert>Please <b onClick={() => history.push(routes.ACCOUNT_BASE)}>Sign In</b> to see your booking.</ErrorAlert>
          </ViewWrapper>
      )
    } else {
      const dateStr = moment(mosqueBooking.date).format('dddd Do MMMM YYYY');
      const eidConfirm = `Please confirm date with Masjid`;
      return (
        <ViewWrapper
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'stretch',
            alignItems: 'center',
            padding: '10px, 5px',
            width: '100%',
            height: '100%',
          }}>
          <BookingQrCode mosqueBooking={mosqueBooking} size={height * 0.4} style={{marginTop: '-50px', flex: 1}} />
            { renderActionButtons() }
            { renderTags() }
            <HeadingText isDark={true}>{mosque.name}</HeadingText>
            <ListCard.Detail icon={faCalendar}>{mosqueBooking.slot.salah == 'eid' && dateStr == 'Invalid date' ? eidConfirm : dateStr}</ListCard.Detail>
            <MosqueMap mosqueWithSlots={mosque} />
            <ListCard.Detail icon={faMapMarkerAlt}>
              {mosque.address}
            </ListCard.Detail>
            <MosqueNotes mosque={mosque} />
        </ViewWrapper>
      );
    }
  }

  return (
    <motion.div style={{flex: 1, width: '100%'}}>
      <AdditionalHeadingView style={{ paddingBottom: '40px' }}>
        {error && <ErrorAlert>{error}</ErrorAlert>}
        { renderSpaceReserved() }
      </AdditionalHeadingView>
      <BottomCardView
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingBottom: '70px',
        }}>
        {renderComponents()}
      </BottomCardView>

      { loading ? <Loading><LoadingCircles>{loading}</LoadingCircles></Loading> :
        <ViewWrapper style={{position: 'fixed', bottom: '60px', fontSize: '0.9em', display: 'flex', width: '100%', justifyContent: 'center', zIndex: 100,}}>
        <AccentButton onClick={props.onClose} style={{ fontSize: '0.9em', width: 'max-content'}}>
          <FontAwesomeIcon icon={faTimes} style={{marginRight: '15px'}}/>
          Close Booking Details
        </AccentButton>
      </ViewWrapper>
      }
    </motion.div>
  );
}

const WhiteBoxRounded = styled(ViewWrapper)`
  background-color: white;
  border: white solid;
  border-radius: 50px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Tag = styled(ViewWrapper)`
  background-color: ${COLOR_TEXT_DARK};
  border: ${COLOR_TEXT_DARK} solid;
  border-radius: 50px;
  padding: 2px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 0.8em;
  color: white;
`;

const Slots = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.1em;
  font-weight: 600;
  padding-bottom: 10px;
`;

const BookingCard = styled(ViewWrapper)`
  border-radius: 10px;
  border: transparent solid 1px;
  background-color: white;
  width: 90%;
`;



const Loading = styled(ViewWrapper)`
  position: absolute;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  top: 0;
  background-color: rgba(255,255,255,0.4);
`;

export default MyBookingDetailsView;
