import ViewWrapper from './theme/ViewWrapper';
import React, { Fragment, useContext } from 'react';
import { AuthContext } from '../providers/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR_TEXT_DARK } from '../constants/styles';
import styled from 'styled-components';
import routes from '../constants/routes';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { useHistory } from 'react-router';
import RcQueueAnim from 'rc-queue-anim';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import HeadingText from './text/HeadingText';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import PrimaryButton from './buttons/PrimaryButton';
import logo from '../assets/logo-03.png';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';

const Menu = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const onDropdownItemPress = (path) => {
    authContext.setShowMenu(false);
    history.push(path);
  };

  const signOut = () =>
    authContext.signOut().then(() => history.push(routes.HOME));

  const DropdownItem = ({ icon, route, children }) => (
    <DropdownItemWrapper onClick={() => onDropdownItemPress(route)}>
      <FontAwesomeIcon
        style={{ marginRight: '10px', width: '20px' }}
        icon={icon}
      />
      {children}
    </DropdownItemWrapper>
  );

  return (
    <RcQueueAnim
      type={['left']}
      ease={['easeOutQuart', 'easeInOutQuart']}
      leaveReverse={true}
      style={
        authContext.showMenu
          ? {
              width: '100%',
              height: '100%',
              flex: 1,
              position: 'fixed',
              top: 0,
              right: 0,
              zIndex: 100,
            }
          : {}
      }
      onClick={() => authContext.setShowMenu(false)}>
      {authContext.showMenu && (
        <DropdownMenu key={2}>
          <ViewWrapper
            style={{ display: 'flex', flexDirection: 'column' }}
            onClick={() => authContext.setShowMenu(false)}>
            <FontAwesomeIcon
              icon={faTimes}
              color={COLOR_TEXT_DARK}
              size={'2x'}
              style={{ alignSelf: 'flex-end' }}
            />
          </ViewWrapper>
          <Logo onClick={() => history.push(routes.HOME)}>
            <img
              src={logo}
              width={'100%'}
              height={'auto'}
              style={{ maxWidth: '600px', display: 'block' }}
              alt=''
            />
          </Logo>
          {authContext.isLoggedIn() && (
            <Fragment>
              <HeadingText isDark={true}>My Account</HeadingText>
              <DropdownItem route={routes.ACCOUNT_DETAILS} icon={faUser}>
                Account Details
              </DropdownItem>
              <DropdownItem route={routes.BOOKINGS_CURRENT} icon={faClock}>
                My Bookings
              </DropdownItem>
              <br />
            </Fragment>
          )}
          <DropdownItem route={routes.MOSQUE_FIND_MOSQUE} icon={faList}>
            Find A Mosque
          </DropdownItem>
          <DropdownItemWrapper
            onClick={() =>
              (window.location.href = 'https://about.salahspace.app')
            }>
            <FontAwesomeIcon
              icon={faInfo}
              style={{ marginRight: '10px', width: '20px' }}
            />
            About Us
          </DropdownItemWrapper>

          <ViewWrapper style={{ flex: 1, marginTop: '30px' }} />
          {authContext.isLoggedIn() ? (
            <PrimaryButton onClick={signOut}>Sign Out</PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={() => onDropdownItemPress(routes.LOGIN_BASE)}>
              Sign In
            </PrimaryButton>
          )}
          <ViewWrapper style={{ flex: 1, paddingBottom: '30px' }} />
        </DropdownMenu>
      )}
    </RcQueueAnim>
  );
};

const Logo = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  align-items: center;
`;

const DropdownMenu = styled(ViewWrapper)`
  height: 100%;
  background-color: white;
  width: 75%;
  max-width: 500px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.3) -10px 0 10px -1px;
  padding: 10px 20px 50px;
  overflow-y: scroll;
`;

const DropdownItemWrapper = styled(ViewWrapper)`
  font-size: 1em;
  font-weight: 600;
  padding: 10px 0;
  color: ${COLOR_TEXT_DARK};
  border-bottom-style: solid;
  border-bottom-color: #e1e1e1;
  border-bottom-width: 1px;
`;

export default Menu;
