import styled from 'styled-components';
import ViewWrapper from './theme/ViewWrapper';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {COLOR_TEXT_DARK, COLOR_TEXT_LIGHT} from '../constants/styles';
import TextWrapper from './theme/TextWrapper';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';

const Card = styled(ViewWrapper)`
  background-color: white;
  border-bottom-style: solid;
  border-color: #eaeaea;
  padding: 10px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  position: relative;
`;

function ListCard(props) {
  const onClick = props.onClick;
  return (
    <Card {...props}>
      {onClick && <ClickArrow />}
      {props.children}
    </Card>
  );
}

function ClickArrow() {
  return (
    <ViewWrapper style={{ position: 'absolute', right: '10px' }}>
      <FontAwesomeIcon icon={faAngleRight} color={'#EAEAEA'} size={'lg'} />
    </ViewWrapper>
  );
}

function Detail(props: { icon: string, isDark?: boolean, fontSize?: string }) {
  const icon = props.icon;
  const color = props.isDark ? COLOR_TEXT_LIGHT : COLOR_TEXT_DARK;
  const fontSize = props.fontSize;
  return (
    <ViewWrapper
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '5px',
        paddingBottom: '15px',
        marginRight: '10px',
        color: color,
        fontSize: fontSize
      }}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          color={color}
          size={'sm'}
          width={'20px'}
          style={{ textAlign: 'center', alignItems: 'center' }}
        />
      )}
      <TextWrapper style={{ marginLeft: '8px' }}>
        {props.children}
      </TextWrapper>
    </ViewWrapper>
  );
}

ListCard.Detail = Detail;
export default ListCard;
