import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import ViewWrapper from '../../components/theme/ViewWrapper';
import LoadingCircles from '../../components/loading/LoadingCircles';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import MosqueBooking from '../../models/MosqueBooking';
import bookingService from '../../services/bookingService';
import { useHistory } from 'react-router';
import ViewWithHeading, {
  AdditionalHeadingView,
} from '../../components/ViewWithHeading';
import BookingsTabs from './BookingsTabs';
import TextWrapper from '../../components/theme/TextWrapper';
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TEXT_DARK,
} from '../../constants/styles';
import moment from 'moment';
import ListCard from '../../components/ListCard';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import styled from 'styled-components';
import MosqueMap from '../../components/MosqueMap';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { AuthContext } from '../../providers/AuthProvider';
import routes from '../../constants/routes';
import {m, motion} from 'framer-motion';
import MyBookingDetailsView from './MyBookingDetailsView';
import {Frame, Page} from 'framer';
import PageIndicators from '../../components/PageIndicators';
import BookingQrCode from './BookingQrCode';
import AccentButton from '../../components/buttons/AccentButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import DangerButton from '../../components/buttons/DangerButton';
import filter from 'lodash/filter';

function MyCurrentBookingsView() {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [mosqueBookings: Array<MosqueBooking>, setMosqueBookings] = useState([]);
  const [bookingDetail: MosqueBooking, setBookingDetail] = useState(null);
  const [error: string, setError] = useState(null);
  const [isLoading: boolean, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isDeleting: boolean, setIsDeleting] = useState(false);

  const constraintsRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    bookingService
      .getCurrentBooking()
      .then((data) => {
        setMosqueBookings(data);
      })
      .catch((e) => setError(e.message))
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (error == null && mosqueBookings == null || mosqueBookings.length === 0) {
      setError('You have no bookings, go to the Mosques Tab to create a new booking.');
    } else {
      setError(null);
    }
  }, [mosqueBookings]);

  function onDelete(booking: MosqueBooking) {
    const newBookings = filter(mosqueBookings, (b) => b.booking_id !== booking.booking_id );
    // const newBookings = mosqueBookings.filter(b => b.booking_id !== booking.booking_id);
    setMosqueBookings(newBookings);
    setBookingDetail(null);
  }
  
  function onChangePage(currentIndex, previousIndex) {
    setCurrentPage(currentIndex);
  }

  function renderSpaceReserved(mosqueBooking : MosqueBooking) {
    if (!error && mosqueBooking != null) {
      return (
        <Fragment>
          <WhiteBoxRounded>
            <TextWrapper
              style={{
                color: COLOR_PRIMARY,
                fontWeight: 800,
                textAlign: 'center',
              }}>
              {mosqueBooking &&
                moment(mosqueBooking.date).format('dddd Do MMMM YYYY')}
              <br />
              Your space has been confirmed
            </TextWrapper>
          </WhiteBoxRounded>
          {mosqueBooking && mosqueBooking.slot && (
            <Slots>
              <ListCard.Detail icon={faDoorOpen} color={'white'} fontSize={'0.6em'}>
                Doors Open: {mosqueBooking.slot.timing.open}
              </ListCard.Detail>
              <ListCard.Detail icon={faClock} color={'white'} fontSize={'0.6em'}>
                Salah Starts: {mosqueBooking.slot.timing.start}
              </ListCard.Detail>
            </Slots>
          )}
          <TextWrapper
            style={{
              fontWeight: '700',
              fontSize: '1.1em',
              textAlign: 'center',
              color: 'white',
              marginBottom: '15px',
            }}>
            Present QR code when entering the Mosque
          </TextWrapper>
        </Fragment>
      );
    }
  }

  function renderComponents() {
    if (isLoading) {
      return (
        <ViewWrapper
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}>
          <LoadingCircles />
        </ViewWrapper>
      );
    } else if (!authContext.isLoggedIn()) {
      return (

          <ViewWrapper
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}>
            <ErrorAlert>Please <b onClick={() => history.push(routes.ACCOUNT_BASE)}>Sign In</b> to see your booking.</ErrorAlert>
          </ViewWrapper>
      )
    } else if (bookingDetail != null) {
      return <MyBookingDetailsView mosqueBooking={bookingDetail} error={error} loadingMessage={isDeleting ? 'Deleting booking...' : null} onDelete={onDelete} onClose={() => setBookingDetail(null)}/>
    } else {
      return (
          <ViewWrapper
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                position: "relative",
                marginBottom: "20px",
              }}>
            <ViewWrapper style={{
              position: "relative",
              height: "95%",
            }}>
              {mosqueBookings != null && mosqueBookings.length > 0 &&
              <Page width={"100%"} height={"100%"} gap={20} padding={10} currentPage={currentPage} onChangePage={onChangePage} style={{backgroundColor: 'transparent'}}>
                {mosqueBookings.map(mosqueBooking => {
                  const mosque = mosqueBooking.masjid;
                  const dateStr = moment(mosqueBooking.date).format('dddd Do MMMM YYYY');
                  const eidConfirm = `Please confirm date with Masjid`;
                  return (
                      <BookingCard key={mosqueBooking.booking_id} whileTap={{ scale: 0.9 }} onTap={() => setBookingDetail(mosqueBooking)} >
                        <TextWrapper style={{fontSize: '1.2em', fontWeight: '800', paddingBottom: '5px'}}>Booking #: {mosqueBooking.booking_number}</TextWrapper>
                        <WhiteBoxRounded>
                          <TextWrapper style={{fontSize: "1em", fontWeight: 700}}>{mosqueBooking.slot.name}</TextWrapper>
                          <TextWrapper style={{fontSize: "1em", fontWeight: 500}}>{mosqueBooking.slot.salah == 'eid' && dateStr == 'Invalid date' ? eidConfirm : dateStr}</TextWrapper>
                        </WhiteBoxRounded>
                        <TextWrapper style={{paddingBottom: "10px", fontSize: "1em", fontWeight: 700}}>{mosque.name}</TextWrapper>
                        <Slots>
                          <ListCard.Detail icon={faDoorOpen}>
                            Doors Open: {mosqueBooking.slot.timing.open}
                          </ListCard.Detail>
                          <ListCard.Detail icon={faClock}>
                            <TextWrapper style={{textTransform: 'capitalize'}}>{mosqueBooking.slot.name} Starts: {mosqueBooking.slot.timing.start}</TextWrapper>
                          </ListCard.Detail>
                        </Slots>
                        <BookingQrCode mosqueBooking={mosqueBooking} style={{flex: 1}}/>
                        {/*<MosqueMap mosqueWithSlots={mosque} />*/}
                        {/*<ListCard.Detail icon={faMapMarkerAlt}>*/}
                        {/*  {mosque.address}*/}
                        {/*</ListCard.Detail>*/}
                        <ViewWrapper
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              paddingBottom: '15px',
                            }}>
                          <Tag>{mosqueBooking.slot.name}</Tag>
                          {mosqueBooking.num_people_male > 1 ? (
                              <Tag>{mosqueBooking.num_people_male} male people</Tag>
                          ) : mosqueBooking.num_people_male > 0 ? (
                              <Tag>{mosqueBooking.num_people_male} male person</Tag>
                          ) : false}
                          {mosqueBooking.num_people_female > 1 ? (
                              <Tag>{mosqueBooking.num_people_female} female people</Tag>
                          ) : mosqueBooking.num_people_female > 0 ? (
                              <Tag>{mosqueBooking.num_people_female} female person</Tag>
                          ) : false}
                        </ViewWrapper>
                      </BookingCard>
                  )
                })}

              </Page>
              }

            </ViewWrapper>
          <PageIndicators pages={mosqueBookings.length} currentPage={currentPage}/>
          </ViewWrapper>
      )
        }
  }

  return (
    <ViewWithHeading id={'#current-booking'}>
      {error &&
      <AdditionalHeadingView>
        <ErrorAlert>{error}</ErrorAlert>
      </AdditionalHeadingView>
      }
      <ViewWrapper
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
          paddingTop: '10px'
        }}>
        {renderComponents()}
      </ViewWrapper>
    </ViewWithHeading>
  );
}

const WhiteBoxRounded = styled(ViewWrapper)`
  background-color: ${COLOR_SECONDARY};
  color: white;
  border: white solid;
  border-radius: 50px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
`;

const Tag = styled(ViewWrapper)`
  background-color: ${COLOR_TEXT_DARK};
  border: ${COLOR_TEXT_DARK} solid;
  border-radius: 50px;
  padding: 2px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 0.8em;
  color: white;
`;

const Slots = styled(ViewWrapper)`
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  font-weight: 600;
  padding-bottom: 10px;
`;

const BookingCard = styled(Frame)`
  border-radius: 30px;
  border: transparent solid 1px;
  background-color: white !important;
  height: 100%;
  margin-bottom: 20px;
  padding: 30px 20px;x;
  display: flex !important;;
  flex: 1 !important;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  box-shadow: rgba(0,0,0,0.3) 0 0 10px;
  &:last-child {
  margin-right: 0;
  }
`;

const BookingCards = styled(Page)`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 0 10px;
  width: max-content;
`;

export default MyCurrentBookingsView;
