import Logger from '../Logger';

class LocationService {
  constructor() {
    this.logger = new Logger(LocationService.name);
  }

  fetchLocation(onSuccess: ({ latitude: number, longitude: number } => void), onError: (errorMessage: string) => void) {
    this.logger.info('Fetching location');

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          onSuccess({ latitude, longitude });
        },
        (error) => {
          let errorMessage = '';

          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage =
                'Please allow location settings or enter your full postcode above.';
              break;
            case error.TIMEOUT:
              errorMessage =
                'Oops, looks like it took too long to find your location, please try again.';
              break;
            default:
              errorMessage =
                'We could not find your location. Please check your location settings.';
          }

          onError(errorMessage);
        },
          {
            timeout: 10000
          }
      );
    } else {
      onError(
        'We could not find your location. Please check your location settings.'
      );
    }
  }
}

export default new LocationService();
