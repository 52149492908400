class MosqueBookingRequest {
  masjid_id: string;
  slot_id: number;
  num_people_male: number;
  num_people_female: number;
  name: string;
  contact: string;

  constructor(
      masjid_id: string, slot_id: number, num_people_male: number,
      num_people_female: number, name: string, contact: string) {
    this.masjid_id = masjid_id;
    this.slot_id = slot_id;
    this.num_people_male = num_people_male;
    this.num_people_female = num_people_female;
    this.name = name;
    this.contact = contact;
  }
}

export default MosqueBookingRequest;