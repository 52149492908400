import styled from 'styled-components';
import ViewWrapper from '../../components/theme/ViewWrapper';
import React, {useEffect, useRef, useState} from 'react';
import MosqueBooking from '../../models/MosqueBooking';
import QRCode from 'qrcode.react';
import { useWindowSize } from 'react-use';

const WhiteBox = styled(ViewWrapper)`
  background-color: white;
  border: white solid;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  height: auto;
  max-width: 500px;
  max-height: 500px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
`;

const BookingQrCode = (props: { mosqueBooking: MosqueBooking, size?: number}) => {
  const ref = useRef(null);
  const {size} = {...props};

  const [qrWidth, setQrWidth] = useState(null);

  useEffect(() => {
    if (size) {
      setQrWidth(size);
    } else if (ref && ref.current) {
      let height = ref.current.clientHeight;
      if (height + 100 > window.innerWidth) {
        setQrWidth(window.innerWidth * 0.6);
      } else if(height > 500) {
        setQrWidth(500);
      } else {
        setQrWidth(ref.current.clientHeight);
      }
    }
  }, [ref]);

  return (
    <WhiteBox ref={ref} style={{...props.style}}>
      {qrWidth && <QRCode value={props.mosqueBooking.qr_code_string} size={qrWidth} />}
      <ViewWrapper style={{ width: '80%' }}></ViewWrapper>
    </WhiteBox>
  );
};

export default BookingQrCode;
