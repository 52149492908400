import ViewWrapper from '../../components/theme/ViewWrapper';
import React, { useContext, useState } from 'react';
import LoadingCircles from '../../components/loading/LoadingCircles';
import ResponsiveView from '../../components/ResponsiveView';
import { AuthContext } from '../../providers/AuthProvider';
import logo from '../../assets/logo-03.png';
import AccentButton from '../../components/buttons/AccentButton';
import styled from 'styled-components';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import TextWrapper from '../../components/theme/TextWrapper';
import EmailSignInView from './email/EmailSignInView';
import EmailSignUpView from './email/EmailSignUpView';
import MagicLinkSignInView from './magiclink/MagicLinkSignInView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { COLOR_TEXT_DARK } from '../../constants/styles';
import WhiteBackground from '../../components/WhiteBackground';
import ErrorAlert from '../../components/alerts/ErrorAlert';

const Image = styled(ViewWrapper)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Buttons = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  font-size: 1em;
  padding-bottom: 50px;
`;

type Props = {
  onClose: () => void,
};

export const LOGIN_VIEWS = {
  EMAIL_SIGN_IN: 'email-sign-in',
  EMAIL_SIGN_UP: 'email-sign-up',
  MAGIC_LINK_SIGN_IN: 'magiclink-sign-in',
};

const LoginStandaloneView = (props: Props) => {
  const authContext = useContext(AuthContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSignInSuccess, setIsSignInSuccess] = useState(false);
  const [loginType, setLoginType] = useState(null);

  const onAnonSignInClick = () => {
    return authContext
      .doAnonymousSignIn()
      .then(() => {
        setIsSignInSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false);
      });
  };

  function renderLoginView() {
    switch (loginType) {
      case LOGIN_VIEWS.EMAIL_SIGN_IN:
        return <EmailSignInView onClose={(type) => setLoginType(type)} />;
      case LOGIN_VIEWS.EMAIL_SIGN_UP:
        return <EmailSignUpView onClose={(type) => setLoginType(type)} />;
      case LOGIN_VIEWS.MAGIC_LINK_SIGN_IN:
        return <MagicLinkSignInView onClose={(type) => setLoginType(type)} />;
      default:
        if (loading) {
          return <LoadingCircles>Signing you in...</LoadingCircles>;
        } else if (isSignInSuccess) {
          return <LoadingCircles>Authenticating you...</LoadingCircles>;
        } else {
          return (
            <WhiteBackground>
              <Image>
                <img
                  src={logo}
                  width={'100%'}
                  height={'auto'}
                  alt={'Salah Space Logo'}
                  style={{ maxWidth: '600px' }}
                />
              </Image>
              {error && <ErrorAlert>{error}</ErrorAlert>}


              <Buttons>
                <AccentButton onClick={() => setLoginType('magiclink-sign-in')}>
                  Sign In with Quick Link
                </AccentButton>
                <PrimaryButton onClick={() => setLoginType('email-sign-in')}>
                  Sign In with Email
                </PrimaryButton>
                <hr />
                <SignUp onClick={() => setLoginType('email-sign-up')}>
                  I don't have an account
                </SignUp>
              </Buttons>
            </WhiteBackground>
          );
        }
    }
  }

  return (
    <WhiteBackground
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}>
      <ViewWrapper
        style={{ width: '100%', textAlign: 'right', padding: '10px 20px' }}
        onClick={() => props.onClose()}>
        <FontAwesomeIcon
          icon={faTimes}
          color={COLOR_TEXT_DARK}
          size={'2x'}
          style={{ alignSelf: 'flex-end' }}
        />
      </ViewWrapper>
      <ResponsiveView>
        {authContext.loading ? (
          <LoadingCircles size={120}>Signing you in</LoadingCircles>
        ) : (
          renderLoginView()
        )}
      </ResponsiveView>
    </WhiteBackground>
  );
};

const SignUp = styled(TextWrapper)`
  font-size: 1.3em;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK} !important;
  padding: 20px 0 10px 0;
  width: 100%;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`;

export default LoginStandaloneView;
