import Slot from './Slot';
import Mosque from './Mosque';

class MosqueBooking {
  booking_id: string;
  masjid: Mosque;
  masjid_id: string;
  slot: Slot;
  num_people_male: number;
  num_people_female: number;
  date: Date;
  qr_code_string: string;
  booking_number: string;

  constructor(
      booking_id: string, masjid: Mosque, masjid_id: string, slot: Slot,
      num_people_male: number, num_people_female: number, date: Date,
      qr_code_string: string, booking_number: string) {
    this.booking_id = booking_id;
    this.masjid = masjid;
    this.masjid_id = masjid_id;
    this.slot = slot;
    this.num_people_male = num_people_male;
    this.num_people_female = num_people_female;
    this.date = date;
    this.qr_code_string = qr_code_string;
    this.booking_number = booking_number;
  }
}

export default MosqueBooking;