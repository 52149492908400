import MosqueWithSlots from '../../models/MosqueWithSlots';
import ViewWrapper from '../theme/ViewWrapper';
import React, { Fragment } from 'react';
import SubheadingText from '../text/SubheadingText';
import {COLOR_TEXT_LIGHT} from '../../constants/styles';

function MosqueNotes(props: { mosque: MosqueWithSlots }) {
  const mosque = props.mosque;
  const isNotesPresent = mosque.notes || null;
  
  return (
    <Fragment>
      <SubheadingText style={{ marginTop: '5px' }} isDark={true}>
        Mosque Updates on COVID-19
        </SubheadingText>
      <ViewWrapper style={{ padding: '3px 10px', whiteSpace: 'pre-wrap' }}>
        {isNotesPresent || 'There are no updates from the mosque'}
      </ViewWrapper>
    </Fragment>

  )
}

export default MosqueNotes;