import React, {Fragment} from 'react';
import SubheadingText from '../../../components/text/SubheadingText';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import MosqueMap from '../../../components/MosqueMap';
import {Marker} from 'react-map-gl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarker} from '@fortawesome/free-solid-svg-icons/faMapMarker';
import {COLOR_PRIMARY} from '../../../constants/styles';
import MosqueWithSlots from '../../../models/MosqueWithSlots';

function BookingMap(props : {mosque: MosqueWithSlots}) {
  const mosque = props.mosque;
  const hasLocation = mosque != null && mosque.location != null && mosque.location.latitude != null && mosque.location.longitude != null;
  return (
      <Fragment>
        <SubheadingText isDark={true}>Location</SubheadingText>
        <ViewWrapper
            style={{
              display: 'Flex',
              justifyContent: 'center',
              width: '100%',
              marginBottom: '10px',
            }}>
          {hasLocation &&
          <MosqueMap mosqueWithSlots={mosque}>
            {hasLocation && (
                <Marker
                    longitude={mosque.location.longitude}
                    latitude={mosque.location.latitude}>
                  <FontAwesomeIcon
                      icon={faMapMarker}
                      color={COLOR_PRIMARY}
                      size={'sm'}
                  />
                </Marker>
            )}
          </MosqueMap>
          }
        </ViewWrapper>
      </Fragment>
  )
}

export default BookingMap;