import React, { Fragment } from 'react';
import TextWrapper from '../theme/TextWrapper';
import styled from 'styled-components';
import {
  COLOR_TEXT_DARK,
  COLOR_TEXT_LIGHT,
  MAX_MOBILE_WIDTH,
} from '../../constants/styles';

const TitleText = (props) => (
  <Fragment>
    <TitleTextWrapper {...props} />
  </Fragment>
);

const TitleTextWrapper = styled(TextWrapper)`
  font-weight: 800;
  font-size: 3em;
  color: white;
  padding-bottom: 15px;
  color: ${(props) => (props.isDark ? COLOR_TEXT_DARK : COLOR_TEXT_LIGHT)};
  @media (min-width: ${MAX_MOBILE_WIDTH}) {
    font-size: 5em;
  }
`;

export default TitleText;
