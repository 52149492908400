import styled from 'styled-components';
import ViewWrapper from './theme/ViewWrapper';

const WhiteBackground = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  flex: 1;
`;

export default WhiteBackground;