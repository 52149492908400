import Mosque from '../../models/Mosque';
import React from 'react';
import TextWrapper from '../../components/theme/TextWrapper';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import ListCard from '../../components/ListCard';
import { faWalking } from '@fortawesome/free-solid-svg-icons/faWalking';

type Props = {
  mosque: Mosque,
};

function SingleMosqueCard(props: Props) {
  return (
    <ListCard {...props}>
      <TextWrapper
        style={{ fontSize: '1.3em', fontWeight: '700' }}
        isDark={true}>
        {props.mosque.name}
      </TextWrapper>
      <ListCard.Detail icon={faMapMarkerAlt}>
        {props.mosque.address}
      </ListCard.Detail>
      <ListCard.Detail icon={faWalking}>
        {props.mosque.distance} miles away
      </ListCard.Detail>
    </ListCard>
  );
}

export default SingleMosqueCard;
