import React, {Fragment} from 'react';
import styled from 'styled-components';
import {
  COLOR_ERROR,
} from '../../constants/styles';
import BaseButton from './BaseButton';

const DangerButton = styled(BaseButton)`
  border-radius: 10px;
  border-color: ${COLOR_ERROR};
  border-style: solid;
  background-color: ${COLOR_ERROR};
  color: white;
`;

export default DangerButton;