import Slot from '../../../models/Slot';
import BookingPanelDetail, {
  BookingPanel,
  BookingPanelDetailTitle,
} from './BookingPanelDetail';
import React, {useEffect, useState} from 'react';
import SelectInput from '../../../components/input/SelectInput';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import size from 'lodash/size';
import Logger from '../../../Logger';
import {faMosque} from '@fortawesome/free-solid-svg-icons/faMosque';

function PrayerTypeSelection(props: { slots: Array<Slot>, onChange: (slots: Array<Slot>) => void }) {
  const logger = new Logger(PrayerTypeSelection.name);

  const {slots, onChange} = {...props};
  const [salahTypes, setSalahTypes] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    setSalahTypes(Object.keys(groupBy(slots, (slot) => slot.salah)));
  }, []);

  useEffect(() => {
    if (size(salahTypes) === 1) {
      setDefaultValue(salahTypes[0]);
    }
  }, [salahTypes]);

  useEffect(() => {
    onSelect(defaultValue);
  }, [defaultValue]);

  // useEffect(() => {
  //   onChange(selectedSlot)
  // }, [selectedSlot]);

  function onSelect(salah) {
    const selectedSlots = filter(slots, (slot) => slot.salah === salah);

    onChange(selectedSlots);
  }

  return (
      <BookingPanel>
        <BookingPanelDetail icon={faMosque} isDark={true}>
          <BookingPanelDetailTitle>
            Select Salah
          </BookingPanelDetailTitle>
        </BookingPanelDetail>
        <SelectInput onChange={(event) => onSelect(event.target.value)} value={defaultValue}>
          <option value={null}>Select Salah</option>
          {map(salahTypes, (salahType) => <option key={salahType} value={salahType}>{salahType}</option>)}
        </SelectInput>
      </BookingPanel>
  );
}

export default PrayerTypeSelection;