import React, {createContext, useEffect, useState} from 'react';
import * as firebase from 'firebase';
import Logger from '../Logger';
import {useLocation} from 'react-router';

export const FirebaseRemoteConfigContext = createContext({});

const PROPERTIES = {
  MAINTENANCE_MODE: "maintenance_enabled"
};

export const FirebaseRemoteConfigProvider = ({children}) => {
  const logger = new Logger(FirebaseRemoteConfigProvider.name);
  // const [remoteConfig : firebase.remoteConfig.RemoteConfig, setRemoteConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    refreshConfig();
  }, [location.pathname]);

  useEffect(() => {
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 3600000, // 1 hour
    };
    remoteConfig.defaultConfig[PROPERTIES.MAINTENANCE_MODE] = false;
    remoteConfig.setLogLevel(process.env.NODE_ENV === 'production' ? 'error' : 'debug');
    remoteConfig.ensureInitialized()
        .then(() => {
          logger.info("Fetched remote config ensureInitialized")
          setLoading(false);
        })
        .catch((e) => {
          logger.error("Failed to fetch remote config", e)
        })
  }, [null]);

  const remoteConfig = firebase.remoteConfig();

  function isMaintenanceMode() {
    const isMaintenance = remoteConfig.getBoolean(PROPERTIES.MAINTENANCE_MODE);
    console.log("remoteConfig.getValue(PROPERTIES.MAINTENANCE_MODE)", isMaintenance);
    return isMaintenance;
  }

  function refreshConfig() {
    remoteConfig.fetchAndActivate()
        .then((status) => {
          logger.info("Fetched remote config updated: ", status);
        })
        .catch((e) => {
          logger.error("Failed to fetch remote config", e)
        });
  }

  return (
      <FirebaseRemoteConfigContext.Provider
          value={{
            remoteConfig,
            isMaintenanceMode,
            loading
          }}
      >
        {children}
      </FirebaseRemoteConfigContext.Provider>
  )

};