import Slot from './Slot';

class MosqueWithSlots {
  masjid_id: string;
  name: string;
  address: string;
  location: {
    latitude: number, longitude: number
  };
  url: string;
  notes: string;
  slots: Array<Slot>;

  constructor(
      masjid_id: string, name: string, address: string,
      location: { latitude: number, longitude: number }, url: string,
      notes: string, slots: Array<Slot>) {
    this.masjid_id = masjid_id;
    this.name = name;
    this.address = address;
    this.location = location;
    this.url = url;
    this.notes = notes;
    this.slots = slots;
  }
}

export default MosqueWithSlots;