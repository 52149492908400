import ViewWithHeading, {
  AdditionalHeadingView,
} from '../../components/ViewWithHeading';
import React, { useContext } from 'react';
import BottomCardView from '../../components/BottomCardView';
import { AuthContext } from '../../providers/AuthProvider';
import {
  DetailsInfo,
  Details,
} from '../../components/InformationDetailComponents';
import image from '../../assets/undraw_onboarding_o8mv.svg';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import routes from '../../constants/routes';
import InfoAlert from '../../components/alerts/InfoAlert';
import { useHistory } from 'react-router';
import ViewWrapper from '../../components/theme/ViewWrapper';
import LoginStandaloneView from '../login/LoginStandaloneView';

function AccountDetailsView() {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  function signOut() {
    authContext.signOut().then(() => history.push(routes.HOME));
  }

  function doNothing() {

  }

  if (authContext.isLoggedIn()) {

    return (
        <ViewWithHeading title={'My Account Details'}>
          <AdditionalHeadingView style={{height: '100px'}}/>
          <BottomCardView style={{alignItems: 'center'}}>
            <img
                src={image}
                height={'auto'}
                width={'80%'}
                style={{maxWidth: '500px', marginTop: '-10vh'}}
                alt=''
            />
            <Details>
              <DetailsInfo label={'Name'}
                           title={authContext.user.name || 'N/A'}/>
              <DetailsInfo
                  label={'Email'}
                  title={authContext.user.email || 'N/A'}
              />
              <DetailsInfo
                  label={'Phone Number'}
                  title={authContext.user.phone || 'N/A'}
              />
              <DetailsInfo label={'UID'} title={authContext.user.uid || 'N/A'}/>
            </Details>

            <ViewWrapper style={{paddingBottom: '20px'}}/>

            <InfoAlert>
              To delete your account or request your data please contact us
              at{' '}
              <a href={'mail:salahspace.app@gmail.com'}>salahspace.app@gmail.com</a>
              <br/>
              For more information you can read our
              {' '}<a
                target={'_blank'}
                rel='noopener noreferrer'
                href={'https://about.salahspace.app/terms-conditions'}>
              terms and conditions
            </a>{' '}
              and{' '}
              <a
                  target={'_blank'}
                  rel='noopener noreferrer'
                  href={'https://about.salahspace.app/privacy-policy'}>
                privacy policy
              </a>{' '}
              provided by Salah Space.
            </InfoAlert>

            <ViewWrapper style={{paddingBottom: '30px'}}/>

            <PrimaryButton onClick={signOut}>Sign Out</PrimaryButton>
          </BottomCardView>
        </ViewWithHeading>
    )
  } else {
    return <LoginStandaloneView onClose={doNothing}/>
  }
}

export default AccountDetailsView;
