import React from 'react';
import styled, { keyframes } from 'styled-components';
import ViewWrapper from '../theme/ViewWrapper';
import { COLOR_ACCENT, COLOR_TEXT_DARK } from '../../constants/styles';
import { pulse } from 'react-animations';
import TextWrapper from '../theme/TextWrapper';

const LoadingCircles = (props) => {
  const size = props.size || 100;
  return (
      <Loading>
    <ViewWrapper
      style={{ width: '100px', height: '100px', position: 'relative' }}>
      <Circle size={size} color={COLOR_ACCENT} left={'20px'} scale={0.9} />
      <Circle
        size={size}
        color={'#53F3C0'}
        right={'5px'}
        scale={0.8}
        animationOffset={0.8}
      />
      <Circle
        size={size}
        color={'#F2E055'}
        bottom={'6px'}
        scale={1}
        animationOffset={1.1}
      />
      <LoadingText>{props.children || 'loading'}</LoadingText>
    </ViewWrapper>
      </Loading>
  );
};



const Loading = styled(ViewWrapper)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
`;

const Circle = styled(ViewWrapper)`
  background-color: ${(props) => props.color};
  position: absolute;
  right: ${(props) => props.right || '0'};
  bottom: ${(props) => props.bottom || '0'};
  left: ${(props) => props.left || '0'};
  mix-blend-mode: screen;
  border-radius: 100%;
  animation: ${keyframes`${pulse}`}
    ${(props) => `${2 * props.scale * props.scale}s`} infinite ease-in-out;
  width: ${(props) => `${props.size * props.scale}px`};
  height: ${(props) => `${props.size * props.scale}px`};
  opacity: 0.7;
`;

const LoadingText = styled(TextWrapper)`
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR_TEXT_DARK};
  font-size: 1.2em;
  font-weight: 800;
`;

export default LoadingCircles;
