import React, { useContext } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import routes from '../../constants/routes';
import EmailSignInView from './email/EmailSignInView';
import MagicLinkVerifyView from './magiclink/MagicLinkVerifyView';
import LoadingCircles from '../../components/loading/LoadingCircles';
import { AuthContext } from '../../providers/AuthProvider';
import LoginStandaloneView from './LoginStandaloneView';

const LoginRouter = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  if (authContext.loading) {
    return <LoadingCircles>Loading app...</LoadingCircles>;
  } else if (authContext.isLoggedIn()) {
    return <Redirect to={routes.ACCOUNT_DETAILS} />;
  } else {
    return (
      <Switch>
        <Route path={routes.LOGIN_MAGIC_EMAIL_VERIFY_LINK}>
          <MagicLinkVerifyView onClose={() => history.goBack()} />
        </Route>
        <Route path={routes.LOGIN_EMAIL}>
          <EmailSignInView onClose={() => history.goBack()} />
        </Route>
        <Route>
          <LoginStandaloneView
            onClose={() => history.push(routes.MOSQUE_FIND_MOSQUE)}
          />
        </Route>
      </Switch>
    );
  }
};

export default LoginRouter;
