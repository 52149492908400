import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles, {COLOR_PRIMARY, COLOR_TEXT_DARK} from '../constants/styles';
import ViewWrapper from './theme/ViewWrapper';
import TextWrapper from './theme/TextWrapper';

const DockPanel = styled(ViewWrapper)`
  background-color: white;
  border: transparent 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 55px;
  box-shadow: rgba(0,0,0,0.1) 0 0 10px;
`;

function Dock(props: {children? : Array<DockItem>}) {
  return (
      <DockPanel {...props}/>
  )
}

function DockItem(props: {icon: string, label:string, selected: boolean, onClick: () => void}) {
  const color = props.selected ? COLOR_PRIMARY : '#a1a1a1';
  return (
      <ViewWrapper
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            color: color
          }}
          onClick={props.onClick}
      >
        <FontAwesomeIcon icon={props.icon} color={color}/>
        <TextWrapper style={{fontSize: '0.8em'}}>{props.label}</TextWrapper>
      </ViewWrapper>
  )
}

export {
  DockItem
};
export default Dock;