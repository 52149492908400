import React, {Fragment} from 'react';
import BookingPanelDetail, {
  BookingPanel,
  BookingPanelDetailTitle,
} from './BookingPanelDetail';
import SelectInput from '../../../components/input/SelectInput';
import {faUserFriends} from '@fortawesome/free-solid-svg-icons/faUserFriends';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfo} from '@fortawesome/free-solid-svg-icons/faInfo';
import TextWrapper from '../../../components/theme/TextWrapper';
import Slot from '../../../models/Slot';
import styled from 'styled-components';

const MAX_NUMBER_OF_ATTENDEES = 5;

function AttendeesSelection(props: {
  selectedSlot: Slot,
  numberOfPeopleFemale: number,
  numberOfPeopleMale: number,
  setNumberOfPeopleFemale: (people: number) => void,
  setNumberOfPeopleMale: (people: number) => void,
}) {

  const {selectedSlot, numberOfPeopleFemale, numberOfPeopleMale, setNumberOfPeopleFemale, setNumberOfPeopleMale} = {...props};

  function renderMaleAttendees() {
    let attendees = MAX_NUMBER_OF_ATTENDEES - numberOfPeopleFemale;
    if (selectedSlot == null) {
      attendees = 0;
    }

    if (attendees > selectedSlot.available_male) {
      attendees = selectedSlot.available_male;
    }

    if (attendees < 0) {
      attendees = 0;
    }
    let options = [<option value={0}>0</option>];
    for (let i = 1; i <= attendees; i++) {
      options.push(<option value={i}>{i}</option>);
    }
    return (
        <AttendeesSelect>
          <AttendeesLabel>Male</AttendeesLabel>
          <SelectInput
              onChange={(event) =>
                  setNumberOfPeopleMale(parseInt(event.target.value))
              }>
            {options}
          </SelectInput>
        </AttendeesSelect>
    );
  }

  function renderFemaleAttendees() {
    let attendees = MAX_NUMBER_OF_ATTENDEES - numberOfPeopleMale;
    if (selectedSlot == null) {
      attendees = 0;
    }

    if (attendees > selectedSlot.available_female) {
      attendees = selectedSlot.available_female;
    }

    if (attendees < 0) {
      attendees = 0;
    }

    let options = [<option value={0}>0</option>];
    for (let i = 1; i <= attendees; i++) {
      options.push(<option value={i}>{i}</option>);
    }
    return (
        <AttendeesSelect>
          <AttendeesLabel>Female</AttendeesLabel>
          <SelectInput
              onChange={(event) =>
                  setNumberOfPeopleFemale(parseInt(event.target.value))
              }>
            {options}
          </SelectInput>
        </AttendeesSelect>
    );
  }

  return (
      <BookingPanel>
        <BookingPanelDetail isDark={true} icon={faUserFriends}>
          <BookingPanelDetailTitle>
            Number of worshippers - including yourself
          </BookingPanelDetailTitle>
        </BookingPanelDetail>
        <ViewWrapper
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              padding: '0 10px',
              width: '100%',
              justifyContent: 'flex-start',
            }}>
          {renderMaleAttendees()}
          {renderFemaleAttendees()}
        </ViewWrapper>
        {numberOfPeopleMale + numberOfPeopleFemale ===
        MAX_NUMBER_OF_ATTENDEES && (
            <MaxAttendees>
              <FontAwesomeIcon
                  icon={faInfo}
                  color={'white'}
                  style={{marginRight: '10px'}}
              />
              <TextWrapper>
                Only {MAX_NUMBER_OF_ATTENDEES} worshippers from the same household can
                attend {selectedSlot.salah}.
              </TextWrapper>
            </MaxAttendees>
        )}
      </BookingPanel>
  );
}

const AttendeesSelect = styled(TextWrapper)`
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AttendeesLabel = styled(TextWrapper)`
  margin-right: 10px;
  color: white;
`;

const MaxAttendees = styled(TextWrapper)`
  padding-top: 10px;
  text-align: center;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
`;

export default AttendeesSelection;