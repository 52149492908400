import React from 'react';
import { Route, Switch } from 'react-router';
import routes from '../../constants/routes';
import AccountDetailsView from './AccountDetailsView';

const AccountRoutes = () => (
  <Switch>
    <Route exact path={routes.ACCOUNT_DETAILS}>
      <AccountDetailsView />
    </Route>
    <Route>
      <AccountDetailsView />
    </Route>
  </Switch>
);

export default AccountRoutes;
