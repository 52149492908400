import * as Sentry from '@sentry/react';
import { APP_ENVIRONMENT } from './appConfig';

export function configureSentry() {
  if (
    process.env.NODE_ENV === 'production' &&
    APP_ENVIRONMENT === 'production'
  ) {
    Sentry.init({
      dsn:
        'https://ba3ce43a3d09482e8ae423480673d97d@o415986.ingest.sentry.io/5308536',
    });
  }
}
