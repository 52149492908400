import ViewWrapper from '../../../components/theme/ViewWrapper';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import mosquesService from '../../../services/mosquesService';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import LoadingCircles from '../../../components/loading/LoadingCircles';
import ViewWithHeading, {
  AdditionalHeadingView,
} from '../../../components/ViewWithHeading';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import MosqueWithSlots from '../../../models/MosqueWithSlots';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import SelectInput from '../../../components/input/SelectInput';
import BottomCardView from '../../../components/BottomCardView';
import SubheadingText from '../../../components/text/SubheadingText';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import moment from 'moment';
import routes from '../../../constants/routes';
import bookingService from '../../../services/bookingService';
import MosqueBookingRequest from '../../../models/MosqueBookingRequest';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { AuthContext } from '../../../providers/AuthProvider';
import BookingMap from './BookingMap';
import BookingPanelDetail, {
} from './BookingPanelDetail';
import MosqueNotes from '../../../components/mosque/MosqueNotes';
import LoginStandaloneView from '../../login/LoginStandaloneView';
import Form from 'react-bootstrap/Form';
import InfoAlert from '../../../components/alerts/InfoAlert';
import Slot from '../../../models/Slot';
import PrayerTypeSelection from './PrayerTypeSelection';
import size from 'lodash/size';
import SlotSelection from './SlotSelection';
import AttendeesSelection from './AttendeesSelection';
import PersonalDetailsForm from './PersonalDetailsForm';

type Props = {};

const MosqueBookingView = (props: Props) => {
  const authContext = useContext(AuthContext);
  const { slug } = useParams();
  const history = useHistory();

  const [mosque: MosqueWithSlots, setMosque] = useState(null);
  const [error: string, setError] = useState(null);
  const [isLoading: boolean, setIsLoading] = useState(true);
  const [isTryingToBookWithoutLogin, setIsTryingToBookWithoutLogin] = useState(
    false
  );
  const [slots, setSlots] = useState([]);

  // inputs
  const [selectedSlot: Slot, setSelectedSlot] = useState(null);
  const [numberOfPeopleMale, setNumberOfPeopleMale] = useState(0);
  const [numberOfPeopleFemale, setNumberOfPeopleFemale] = useState(0);
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    mosquesService
      .getMosquesBySlug(slug)
      .then((data) => setMosque(data))
      .catch((e) => setError(e.message))
      .then(() => setIsLoading(false));
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error]);

  function bookMosque() {
    if (authContext.isLoggedIn()) {
      if (numberOfPeopleFemale + numberOfPeopleMale === 0) {
        setError('You must select at least one male or female worshipper.');
      } else if (selectedSlot == null) {
        setError('You must select at Jumuah slot.');
      } else {
        setIsLoading(true);
        setError(null);
        const booking = new MosqueBookingRequest(
          mosque.masjid_id,
          selectedSlot.slot_id,
          numberOfPeopleMale,
          numberOfPeopleFemale,
          name,
          contact
        );
        bookingService
          .bookMosque([booking])
          .then((data) => {
            history.push(`${routes.BOOKINGS_CURRENT}`);
          })
          .catch((e) => setError(e.message))
          .finally(() => setIsLoading(false));
      }
    } else {
      setIsTryingToBookWithoutLogin(true);
    }
  }

  function renderComponents() {
    if (isLoading) {
      return (
        <ViewWrapper
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}>
          <LoadingCircles />
        </ViewWrapper>
      );
    } else {
      return (
        <Fragment>
          {error && <ErrorAlert>{error}</ErrorAlert>}
          <AdditionalHeadingView>
            {error && mosque == null ? null : (
              <Fragment>
                <PrayerTypeSelection slots={mosque.slots} onChange={setSlots}/>
                {size(slots) > 0 && <SlotSelection slots={slots} onChange={setSelectedSlot} />}
                {selectedSlot && <AttendeesSelection
                    selectedSlot={selectedSlot}
                    numberOfPeopleMale={numberOfPeopleMale}
                    numberOfPeopleFemale={numberOfPeopleFemale}
                    setNumberOfPeopleMale={setNumberOfPeopleMale}
                    setNumberOfPeopleFemale={setNumberOfPeopleFemale}
                />}
              </Fragment>
            )}
          </AdditionalHeadingView>
          <BottomCardView
            style={{ display: 'flex', flex: 1, justifyContent: 'flex-start', borderRadius: '20px' }}>
            <BookingMap mosque={mosque} />
            {error && mosque == null ? (
              <Fragment>
                <ErrorAlert>{error}</ErrorAlert>
              </Fragment>
            ) : (
              <Fragment>
                <AddressBookingPanel>
                  <BookingPanelDetail
                    icon={faMapMarkerAlt}>
                    {mosque ? mosque.address : ''}
                  </BookingPanelDetail>
                </AddressBookingPanel>
                <MosqueNotes mosque={mosque} />
              </Fragment>
            )}
          </BottomCardView>
          <ViewWrapper style={{ display: 'flex', flexDirection: 'column' }}>
            {selectedSlot && <PersonalDetailsForm setContact={setContact} setName={setName}/>}
            <PrimaryButton disabled={mosque == null} onClick={bookMosque}>
              Book my space
            </PrimaryButton>
          </ViewWrapper>
        </Fragment>
      );
    }
  }

  return (
    <ViewWrapper style={{ flex: 1, display: 'flex' }}>
      {isTryingToBookWithoutLogin ? (
        <LoginStandaloneView
          onClose={() => setIsTryingToBookWithoutLogin(false)}
        />
      ) : (
        <ViewWithHeading title={mosque ? mosque.name : 'Mosque Booking'}>
          {renderComponents()}
        </ViewWithHeading>
      )}
    </ViewWrapper>
  );
};

const FormInput = styled(ViewWrapper)`
  width: 100%;
  margin-top: 20px;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const AddressBookingPanel = styled(ViewWrapper)`
  padding: 3px 10px;
  border-bottom: #e1e1e1 solid 1px;
`;

export default MosqueBookingView;
