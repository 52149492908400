import React from 'react';
import ViewWithHeading, {
  AdditionalHeadingView,
} from '../../components/ViewWithHeading';
import BookingsTabs from './BookingsTabs';
import BottomCardView from '../../components/BottomCardView';
import TitleText from '../../components/text/TitleText';
import { useHistory } from 'react-router';

const MyPreviousBookingsView = () => {
  const history = useHistory();

  return (
    <ViewWithHeading title={'My Bookings History'}>
      <AdditionalHeadingView style={{ paddingBottom: '40px' }}>
        <BookingsTabs
          onChange={(route) => history.push(route)}
          selected={BookingsTabs.HISTORY}
        />
      </AdditionalHeadingView>
      <BottomCardView
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
        <TitleText isDark={true}>Coming Soon...</TitleText>
      </BottomCardView>
    </ViewWithHeading>
  );
};

export default MyPreviousBookingsView;
