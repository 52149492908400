import React, { createContext, useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import routes from '../constants/routes';
import userService from '../services/userService';
import User from '../models/User';
import Logger from '../Logger';
import { addAxiosAuthHeader } from '../config/axiosConfig';
import { useLocation } from 'react-router';

/**
 * This provider is created
 * to access user in whole app
 */

export const AuthContext = createContext({});

const STORAGE_MAGIC_LINK_EMAIL_KEY = 'AuthProvider.emailForMagicLinkSignIn';
// const STORAGE_EMAIL_KEY = "AuthProvider.emailForMagicLinkSignIn";

export const AuthProvider = ({ children }) => {
  const logger = new Logger(AuthProvider.name);
  const [user: firebase.User, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(null);
  const [redirectPath, setRedirectPath] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(onAuthStateChanged);
  }, []);

  useEffect(() => {
    if (!isLoggedIn() && !location.pathname.startsWith('/login')) {
      const path = location.pathname + location.search;
      logger.info('Changing redirect path', path);
      setRedirectPath(path);
    }
  }, [location.pathname]);

  async function onAuthStateChanged(firebaseUser) {
    setLoading(true);
    if (firebaseUser) {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      addAxiosAuthHeader(idToken);
      logger.info('firebaseUser', firebaseUser);
      try {
        const user = await userService.getUserByFirebaseId(firebaseUser.uid);
        logger.info('logged in user successfully', firebaseUser);
        setIsNewUser(user == null);
      } catch (e) {
        logger.error('failed to log in user', firebaseUser, e);
        setIsNewUser(true);
      }
    }
    setUser(firebaseUser);
    setLoading(false);
  }

  async function doSendMagicEmailLoginLink(email: string) {
    logger.info('sending magic link to ' + email);
    const response = await firebase.auth().sendSignInLinkToEmail(email, {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: `${window.location.origin}${routes.LOGIN_MAGIC_EMAIL_VERIFY_LINK}?redirect=${redirectPath}`,
      // This must be true.
      handleCodeInApp: true,
    });
    window.localStorage.setItem(STORAGE_MAGIC_LINK_EMAIL_KEY, email);
    return response;
  }

  async function doMagicEmailSignIn(link: string) {
    logger.info('signing in with magic link to ' + link);
    const email = window.localStorage.getItem(STORAGE_MAGIC_LINK_EMAIL_KEY);
    await firebase.auth().signInWithEmailLink(email, link);
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirect');
    if (redirectUrl) {
      setRedirectPath(redirectUrl);
    }
  }

  async function doAnonymousSignIn() {
    logger.info('signing in anon');
    return await firebase.auth().signInAnonymously();
  }

  async function doCreateUserWithEmailAndPassword(
    email: string,
    password: string
  ) {
    logger.info('signing in with email ' + email);
    return await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
  }

  async function doEmailSignIn(email: string, password: string) {
    logger.info('signing in with email ' + email);
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  }

  async function doEmailSendPasswordResetEmail(email: string) {
    logger.info('sending password reset for email ' + email);
    return await firebase.auth().sendPasswordResetEmail(email, {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: `${window.location.origin}${routes.LOGIN_EMAIL_PASSWORD_RESET}`,
      // This must be true.
      handleCodeInApp: true,
    });
  }

  async function doEmailConfirmPasswordReset(
    email: string,
    newPassword: string
  ) {
    logger.info('confirming password reset for email ' + email);
    return await firebase.auth().confirmPasswordReset(email, newPassword);
  }

  async function doSignInToServer(user: User) {
    const response = await userService.signInToServer(user);
    setIsNewUser(false);
    setUser(response);
  }

  async function signOut() {
    logger.info('signing out', user);
    return await firebase.auth().signOut();
  }

  function isLoggedIn() {
    const isLoggedIn = user != null;
    logger.info('is logged in', isLoggedIn);
    return isLoggedIn;
  }

  function getCurrentFirebaseUser() {
    return firebase.auth().currentUser;
  }

  function isAnonymous() {
    return user != null && user.isAnonymous;
  }

  return (
    <AuthContext.Provider
      value={{
        redirectPath,
        setRedirectPath,
        loading,
        isNewUser,
        user,
        showMenu,
        setShowMenu,
        isAnonymous,
        isLoggedIn,
        getCurrentFirebaseUser,
        signOut,
        doSendMagicEmailLoginLink,
        doMagicEmailSignIn,
        // Email
        doEmailSignIn,
        doEmailSendPasswordResetEmail,
        doEmailConfirmPasswordReset,
        doCreateUserWithEmailAndPassword,

        doAnonymousSignIn,
        doSignInToServer,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
