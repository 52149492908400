import React from 'react';
import ViewWrapper from '../theme/ViewWrapper';
import styled from 'styled-components';
import { COLOR_TEXT_LIGHT } from '../../constants/styles';

const Input = styled.select`
  background-color: transparent;
  border: none;
  flex: 1;
  color: ${COLOR_TEXT_LIGHT};
`;

const InputWrapper = styled(ViewWrapper)`
  background-color: transparent;
  color: ${COLOR_TEXT_LIGHT};
  border-radius: 50px;
  border-style: solid;
  border-color: white;
  border-width: 1px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  width: 100%;
`;

type Props = {
  onChange: () => void,
};

function SelectInput(props: Props) {
  return (
    <InputWrapper>
      <Input {...props} style={{textTransform: 'capitalize',  ...props.style}}/>
    </InputWrapper>
  );
}

export default SelectInput;
