import React, { Fragment } from 'react';
import styled from 'styled-components';
import { COLOR_PRIMARY, COLOR_PRIMARY_LIGHT } from '../../constants/styles';
import BaseButton from './BaseButton';

const PrimaryButton = styled(BaseButton)`
  border-radius: 10px;
  border-color: ${(props) => (props.disabled ? '#e1e1e1' : 'white')};
  border-style: solid;
  background-color: ${(props) => (props.disabled ? '#e1e1e1' : 'white')};
  color: ${(props) => (props.disabled ? COLOR_PRIMARY_LIGHT : COLOR_PRIMARY)};
`;

export default PrimaryButton;
