import React, { Fragment } from 'react';
import ViewWrapper from '../theme/ViewWrapper';
import styled from 'styled-components';
import TextWrapper from '../theme/TextWrapper';
import { COLOR_ACCENT } from '../../constants/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';

const SuccessAlert = (props) => (
  <Fragment>
    <AlertView {...props}>
      <FontAwesomeIcon icon={faThumbsUp} color={'white'} size={'lg'} />
      <AlertText>{props.children}</AlertText>
    </AlertView>
  </Fragment>
);

const AlertView = styled(ViewWrapper)`
  background-color: ${COLOR_ACCENT};
  border-color: ${COLOR_ACCENT};
  border-radius: 10px;
  border-style: solid;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: max-content;
`;

const AlertText = styled(TextWrapper)`
  font-size: 1em;
  font-weight: 800;
  color: white;
  text-align: center;
`;

export default SuccessAlert;
