export default {
  HOME: '/',
  QR_READER: '/qr',

  LOGIN_BASE: '/login',
  LOGIN_MAGIC_EMAIL_LINK: '/login/magic-link/sign-in',
  LOGIN_MAGIC_EMAIL_VERIFY_LINK: '/login/magic-link/verify',
  LOGIN_EMAIL: '/login/email',
  LOGIN_EMAIL_SIGN_UP: '/login/email/sign-up',
  LOGIN_EMAIL_PASSWORD_RESET: '/login/email/reset',
  LOGOUT: '/logout',

  MOSQUE_BASE: '/mosque',
  MOSQUE_FIND_MOSQUE: '/mosque/find',
  MOSQUE_BOOK: '/mosque/book',

  BOOKINGS_BASE: '/bookings',
  BOOKINGS_DETAIL: '/bookings/detail',
  BOOKINGS_CURRENT: '/bookings/current',
  BOOKINGS_HISTORY: '/bookings/history',

  ACCOUNT_BASE: '/account',
  ACCOUNT_DETAILS: '/account/details',

  ADD_MOSQUE: '/add-mosque',
};
