// @flow
import ViewWrapper from '../../components/theme/ViewWrapper';
import React, { useState, useEffect } from 'react';
import Mosque from '../../models/Mosque';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import SingleMosqueCard from './SingleMosqueCard';
import LoadingCircles from '../../components/loading/LoadingCircles';
import QueueAnim from 'rc-queue-anim';
import ViewWithHeading, {
  AdditionalHeadingView,
} from '../../components/ViewWithHeading';
import SearchInput from '../../components/input/SearchInput';
import mosquesService from '../../services/mosquesService';
import routes from '../../constants/routes';
import { useHistory } from 'react-router';
import locationService from '../../services/locationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow';
import styled from 'styled-components';
import { useDebounce } from 'react-use';
import BottomCardView from '../../components/BottomCardView';

const FindAMosqueView = () => {
  const [mosques: Array<Mosque>, setMosques] = useState([]);
  const [error: string, setError] = useState(null);
  const [postcode: string, setPostcode] = useState('');
  const [loadingMessage: string, setLoadingMessage] = useState(null);
  const [
    location: { latitude: number, longitude: number },
    setLocation,
  ] = useState({});

  const history = useHistory();

  useEffect(() => {
    setLoadingMessage('Fetching location...');
    setError(null);
    locationService.fetchLocation(
      (location) => {
        setLocation(location);
        setLoadingMessage(null);
      },
      (errorMessage) => {
        setError(errorMessage);
        setLoadingMessage(null);
      }
    );
  }, []);

  useEffect(() => {
    getMosquesByLocation();
  }, [location]);

  useDebounce(
    () => {
      getMosquesByPostcode(postcode);
    },
    1000,
    [postcode]
  );

  function isPostcodeEntered() {
    return postcode !== '' && postcode != null;
  }

  function getMosquesByLocation() {
    if (location && location.latitude && location.longitude) {
      setLoadingMessage('Fetching mosques near you...');
      setError(null);
      mosquesService
        .getMosquesByLocation(location.latitude, location.longitude)
        .then((data) => setMosques(data))
        .catch((e) => setError(e.message))
        .finally(() => setLoadingMessage(null));
    }
  }

  function getMosquesByPostcode(postcode: string) {
    if (isPostcodeEntered()) {
      setLoadingMessage('Fetching mosques near ' + postcode + '...');
      setError(null);
      mosquesService
        .getMosquesByPostcode(postcode)
        .then((data) => setMosques(data))
        .catch((e) => setError(e.message))
        .finally(() => setLoadingMessage(null));
    }
  }

  function goToBookingForMosque(mosque: Mosque) {
    console.log('clicked book mosque');
    history.push(routes.MOSQUE_BOOK + `/${mosque.slug}`);
  }

  function renderComponents() {
    if (loadingMessage) {
      return <LoadingCircles>{loadingMessage}</LoadingCircles>;
    } else if (error) {
      return (
        <ViewWrapper
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}>
          <ErrorAlert>{error}</ErrorAlert>
        </ViewWrapper>
      );
    } else if (mosques === null || mosques.length === 0) {
      return (
        <ErrorAlert>
          There are currently no Mosques near you, try entering a postcode.
        </ErrorAlert>
      );
    } else {
      return (
        <QueueAnim
          delay={500}
          type={['right', 'left']}
          ease={['easeOutQuart', 'easeInOutQuart']}>
          {mosques.map((mosque) => (
            <SingleMosqueCard
              key={mosque.masjid_id}
              mosque={mosque}
              onClick={() => goToBookingForMosque(mosque)}
            />
          ))}
        </QueueAnim>
      );
    }
  }

  return (
    <ViewWrapper style={{ height: '100%', flex: 1, display: 'flex' }}>
      <ViewWithHeading title={'Find a Mosque'}>
        <AdditionalHeadingView>
          <SearchBar>
            <SearchInput
              placeholder={'Please enter your full postcode'}
              onChange={(event) => setPostcode(event.target.value)}
            />
            <LocationIcon>
              <FontAwesomeIcon
                icon={faLocationArrow}
                color={'white'}
                onClick={() => getMosquesByLocation()}
              />
            </LocationIcon>
          </SearchBar>
        </AdditionalHeadingView>
        <BottomCardView style={{ display: 'flex', flex: 1 }}>
          <ViewWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              width: '100%',
            }}>
            {renderComponents()}
          </ViewWrapper>
        </BottomCardView>
      </ViewWithHeading>
    </ViewWrapper>
  );
};

const SearchBar = styled(ViewWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const LocationIcon = styled(ViewWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export default FindAMosqueView;
