import React, { useContext, useState } from 'react';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { AuthContext } from '../../../providers/AuthProvider';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import LoadingCircles from '../../../components/loading/LoadingCircles';
import BottomCardView from '../../../components/BottomCardView';
import { Link } from 'react-router-dom';
import { LOGIN_VIEWS } from '../LoginStandaloneView';

type Props = {
  onClose: (type?: string) => void,
};

function EmailSignUpView(props: Props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isUserCreted, setIsUserCreated] = useState(false);

  const authContext = useContext(AuthContext);

  function onSubmit() {
    if (password !== confirmPassword) {
      setError('Passwords must match');
    } else {
      setLoading(true);
      setError(null);
      authContext
        .doCreateUserWithEmailAndPassword(email, password)
        .then(() => setIsUserCreated(true))
        .catch((e) => setError(e.message))
        .finally(() => setLoading(false));
    }
  }

  function renderElements() {
    if (loading) {
      return <LoadingCircles>Signing you in...</LoadingCircles>;
    } else if (isUserCreted) {
      return <LoadingCircles>Authenticating you...</LoadingCircles>;
    } else {
      return (
        <View>
          {error && <ErrorAlert>{error}</ErrorAlert>}
          <FormView>
            <FormInput>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter your email'
                size={'lg'}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormInput>
            <FormInput style={{ width: '100%', paddingTop: '10px' }}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter your password'
                size={'lg'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormInput>
            <FormInput style={{ width: '100%', paddingTop: '10px' }}>
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm your password'
                size={'lg'}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </FormInput>
          </FormView>
          <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
          <hr />
          <Link
            onClick={() => props.onClose(LOGIN_VIEWS.MAGIC_LINK_SIGN_IN)}
            to={'#'}>
            Or login without a password?
          </Link>
          <hr />

          <FormInput
            style={{
              width: '100%',
              paddingTop: '10px',
              borderTop: 'solid #e1e1e1 1px',
              marginTop: '10px',
            }}>
            <Link
              to={'#'}
              style={{ fontStyle: 'underlined' }}
              onClick={() => props.onClose()}>
              Go back
            </Link>
          </FormInput>
        </View>
      );
    }
  }

  return <BottomCardView>{renderElements()}</BottomCardView>;
}

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
`;

const FormInput = styled(ViewWrapper)`
  width: 100%;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const FormView = styled(ViewWrapper)`
  width: 100%;
  padding: 10px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

export default EmailSignUpView;
