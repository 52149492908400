import React from 'react';
import styled from 'styled-components';
import routes from '../../constants/routes';
import {motion} from 'framer-motion';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Tab = styled(motion.div)`
  padding: 5px 20px;
  text-align: center;
  min-width: 150px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  background-color: ${(props) =>
    props.active ? 'rgba(255,255,255, 0.4)' : 'transparent'};
  border: 1px solid #fff;
  border-radius: 20px;
  margin-right: 1rem;
  &:last-child,
  &:last-of-type {
    margin-right: 0;
  }
`;

const BookingsTabs = ({ onChange, selected }) => (
  <Container>
    <Tab
      whileTap={{ scale: 0.9 }}
      onTap={() => onChange(routes.BOOKINGS_CURRENT)}
      active={selected === BookingsTabs.UP_COMING}>
      Upcoming
    </Tab>
    <Tab
      whileTap={{ scale: 0.9 }}
      onTap={() => onChange(routes.BOOKINGS_HISTORY)}
      active={selected === BookingsTabs.HISTORY}>
      History
    </Tab>
  </Container>
);

BookingsTabs.HISTORY = 'history';
BookingsTabs.UP_COMING = 'up-coming';
export default BookingsTabs;
